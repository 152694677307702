:root {
  /* Custom Color ######################################### */
  --theme-primary-color: #fec678;
  --header-bg-color: #fec678;
  --header-alpha-percentage: 100%;
  --footer-bg-color: #000;

  /* Custom Image (Better to use webp) #################### */
  --default-light-banner-url: url(../images/default.webp);
  --profile-light-banner-url: url(../images/default.webp);
  /* --index-light-banner-url: url(#); */
  --collection-light-banner-url: url(../images/default.webp);
  --launchpad-light-banner-url: url(../images/default.webp);
  --faq-light-banner-url: url(../images/default.webp);

  /* Custom Font ########################################## */
  --custom-font-400-url: url(#); /* woff2 format */
  --custom-font-bold-url: url(#); /* woff2 format */

  /* Template defined (Usually no need to edit) */
  --primary-color: #fff;
  --primary-color2: #000;
  --primary-color3: var(--theme-primary-color);
  --primary-color4: #000;
  --primary-color5: #f8f8f8;
  --primary-color6: #000;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: var(--theme-primary-color);
  --primary-color9: var(--theme-primary-color);
}

@font-face {
  font-family: 'CustomFont';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: var(--custom-font-400-url) format('woff2');
}

@font-face {
  font-family: 'CustomFont';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: var(--custom-font-bold-url) format('woff2');
}

.heading {
  margin-top: 60px !important;
}

body,
button,
input,
select,
textarea {
  font-family: 'CustomFont', 'Urbanist', sans-serif !important;
}

.flat-title-page.inner {
  padding: 200px 0;
}

#footer {
  background-color: var(--primary-color6) !important;
}

#footer h5,
#footer .mg-t-20,
.widget-menu ul li a {
  color: var(--primary-color) !important;
}

.widget-social.style-1 ul li a,
.menu_card .dropdown-menu a {
  color: var(--footer-bg-color) !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  background-color: var(--primary-color3) !important;
  border-color: var(--primary-color3) !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  border: 2px solid var(--primary-color) !important;
}

/* Searchbox */
input[type='search'] {
  border: thin solid var(--primary-color) !important;
}

/* Banner */
.mainslider .swiper-button-prev,
.mainslider .swiper-button-next {
  background: var(--primary-color4) !important;
}

.header_1.header_2.style2 {
  background: color-mix(
    in srgb,
    var(--header-bg-color) var(--header-alpha-percentage),
    transparent
  ) !important;
}

#footer {
  background-color: var(--footer-bg-color) !important;
}

.wrap-cart .cart_item.style2 p {
  text-shadow: 2px 2px var(--primary-color4) !important;
}

/* Index Swiper */
.flat-cart-item .overlay {
  background: var(--header-bg-color) !important;
}

/* Index Banner Image */
.flat-cart-item .overlay {
  background-size: cover !important;
  background-color: #f8f8f8 !important;
}

/* Collection Banner Image */
#\/collection-banner {
  background: var(--collection-light-banner-url) center center no-repeat !important;
  background-size: cover !important;
}

/* Launchpad Banner Image */
#\/launchpad-banner {
  background: var(--launchpad-light-banner-url) center center no-repeat !important;
  background-size: cover !important;
}

/* FAQ Banner Image */
#\/faq-banner {
  background: var(--faq-light-banner-url) center center no-repeat !important;
  background-size: cover !important;
}
